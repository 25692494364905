import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import logo from "@/assets/DDDI.png";
import styles from "./NavBar.module.scss";

import { SearchOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { Drawer, Input } from "antd";

import NavDrawer from "./NavDrawer";

import navLinkGroup from "@/data/header-link/linkGroup.json";
import { useTranslation } from "react-i18next";
import NewsroomPage from "@/pages/newsroom/NewsroomPage";


const NavBar = () => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(() => localStorage.getItem('language') || 'en');

  useEffect(() => {
    // 组件加载时从localStorage获取语言设置
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      setLanguage(savedLanguage);
      i18n.changeLanguage(savedLanguage);
    }
  }, []);

  const toggleLanguage = (event) => {
    event.preventDefault();
    const newLang = language === 'en' ? 'zh' : 'en';
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  const navigate = useNavigate();
  const CapabilitiesLinkGroup = navLinkGroup.CapabilitiesLinkGroup;
  const partnersLinkGroup = navLinkGroup.partnersLinkGroup;
  const ResearchLinkGroup = navLinkGroup.ResearchLinkGroup;

  const ImpactLinkGroup = navLinkGroup.ImpactLinkGroup;

  const AboutLinkGroup = navLinkGroup.AboutLinkGroup;
  const NewsLinkGroup = navLinkGroup.NewsLinkGroup;

  const [isOpen, setIsOpen] = useState(false);
  const [navActiveIndex, setNavActiveIndex] = useState([]);
  const handleMDNavItemClick = (index, link = null) => {
    const list = navActiveIndex;
    if (list.includes(index)) {
      setNavActiveIndex(navActiveIndex.filter((item) => item !== index));
    } else {
      setNavActiveIndex([...navActiveIndex, index]);
    }
    if (link) {
      navigate(link);
    }
  };

  const [isSticky, setIsSticky] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // 添加阈值判断，避免小幅度滚动触发状态改变
      const threshold = 5;

      // 处理Safari回弹问题
      if (currentScrollY <= 0) {
        setIsSticky(true);
        return;
      }

      if (currentScrollY > lastScrollY + threshold && !isOpen) {
        // 向下滚动超过阈值
        setIsSticky(false);
      } else if (currentScrollY < lastScrollY - threshold) {
        // 向上滚动超过阈值
        setIsSticky(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY, isOpen]);

  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const onCloseSearchDrawer = () => {
    setSearchText("");
    setSearchDrawerOpen(false);
  };
  const onSearch = () => {
    navigate("/search-results?q=" + searchText);
  };

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full lg:px-14 md:px-8 px-6 ${styles["header"]
          } ${styles["header-md"]} ${isOpen ? styles["header-md-open"] : ""} ${!isSticky ? styles["header-scroll"] : ""
          }`}
      >
        <div className={`flex justify-between items-center mx-auto h-full`} style={{ alignItems: "center" }}>
          <div className="flex-shrink-0 relative z-10">
            <a href="/">
              <img className="object-contain" src={logo} style={{ height: 55 }} alt="DDDI Group" />
            </a>
          </div>
          
          <nav className={`flex items-center relative ${styles["head-nav"]} `} style={{ alignItems: "center" }}>
            <button
              className={`md:block lg:hidden  touch-manipulation absolute right-0 z-10 mt-0`}
            >
              <CloseOutlined
                className={`${styles["close-btn"]} size-40px p-4 pl-2 ${isOpen ? "block" : "hidden"
                  } `}
                style={{ color: "#0a1e41", fontSize: "1.4rem" }}
                onClick={() => {
                  setIsOpen(false);
                  setNavActiveIndex([]);
                }}
              />
              <MenuOutlined
                className={`${styles["menu-btn"]}  size-40px p-4 pl-2 ${isOpen ? "hidden" : "block"
                  } `}
                style={{ color: "#0a1e41", fontSize: "1.4rem" }}
                onClick={() => setIsOpen(true)}
              />
            </button>

            <ul
              className={`lg:flex hidden items-center mr-3 ${navActiveIndex.length !== 0 ? styles["head-active-bg"] : ""
                }`}
            >
              <li className="">
                <a href="/" className={`${styles["nav-item"]}`}>
                  {t('1')}
                </a>
              </li>

              {/* RESEARCHES */}
              <li className={`${styles["nav-li"]} ${navActiveIndex.indexOf(2) !== -1 ? styles["nav-li-open"] : ""}`}>
                <div className={`${styles["nav-drawer-mask"]} `}></div>

                <li className="">
                  <a href="/researches" className={`${styles["nav-item"]}`}>
                    {t('researches')}
                  </a>
                </li>
                <NavDrawer
                  title={t('researches')}
                  description=""
                  btnText={t('learnMore')}
                  btnLink="/researches"
                  linkGroup={ResearchLinkGroup}
                ></NavDrawer>
              </li>



              {/* NEWS */}
              <li className="">
                <Link to="/newsroom" className={`${styles["nav-item"]}`}>
                  {t('4')}
                </Link>
              </li>

              {/* PROJECTS */}
              <li className="">
                <a href="/projects" className={`${styles["nav-item"]}`}>
                  {t('projects')}
                </a>
              </li>



              {/* SERVICES */}
              {/* <li className={`${styles["nav-li"]} ${navActiveIndex.indexOf(2) !== -1 ? styles["nav-li-open"] : ""
                }`}>
                <div className={`${styles["nav-drawer-mask"]} `}></div>
                <span
                  className={`${styles["nav-item"]} hover:cursor-default `}
                  onClick={() => handleMDNavItemClick(2, "/capabilities")}
                >
                  {t('services')}
                </span>
                <NavDrawer
                  title={t('services')}
                  description=""
                  btnText={t('learnMore')}
                  btnLink="/capabilities"
                  linkGroup={CapabilitiesLinkGroup}
                ></NavDrawer>
              </li> */}

              {/* PROUD GROUP MEMBERS */}
              <li className={`${styles["nav-li"]} ${navActiveIndex.indexOf(3) !== -1 ? styles["nav-li-open"] : ""
                }`}>
                <div className={`${styles["nav-drawer-mask"]}`}></div>
                <li className="">
                  <a href="/group-members" className={`${styles["nav-item"]}`}>
                    {t('3')}
                  </a>
                </li>
                <NavDrawer
                  title={t('members')}
                  description={t('73')}
                  btnText={t('learnMore')}
                  btnLink="/group-members"
                  linkGroup={ImpactLinkGroup}
                ></NavDrawer>
              </li>

              {/* ABOUT US */}
              <li className={`${styles["nav-li"]} ${navActiveIndex.indexOf(3) !== -1 ? styles["nav-li-open"] : ""
                }`}>
                <div className={`${styles["nav-drawer-mask"]}`}></div>
                <li className="">
                  <a href="/brandStory" className={`${styles["nav-item"]}`}>
                    {t('6')}
                  </a>
                </li>
                <NavDrawer
                  title={t('6')}
                  description={t('74')}
                  btnText={t('learnMore')}
                  btnLink="/brandStory"
                  linkGroup={AboutLinkGroup}
                ></NavDrawer>
              </li>

              {/* Language Toggle */}
              <li className="cursor-pointer" onClick={toggleLanguage}>
                <a href="#" className={`${styles["nav-item"]}`}>
                  {language === 'en' ? 'EN / 中文' : '中文 / EN'}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <div
        className={`w-full h-0 ${!isOpen ? styles["anti-collapse"] : ""}`}
      ></div>

      <Drawer
        title=""
        placement="bottom"
        height={"100vh"}
        width={"100vw"}
        closeIcon={false}
        onClose={onCloseSearchDrawer}
        open={searchDrawerOpen}
        styles={{
          header: {
            borderBottom: `0px`,
          },
          body: {
            padding: `0px`,
            backgroundColor: `#F3F6FA`,
          },
        }}
      >
        <div
          className={`mx-auto w-full lg:pt-10 md:pt-8 pt-6 lg:max-w-screen-lg lg:px-14 md:max-w-screen-md md:px-8 px-6`}
        >
          <div className={`text-right lg:my-5`}>
            <CloseOutlined
              className="cursor-pointer"
              onClick={onCloseSearchDrawer}
              style={{
                fontSize: "1.4rem",
              }}
            />
          </div>
          <div className={`mt-20 border-b-2 border-slate-900`}>
            <Input
              variant="borderless"
              placeholder="Search for Reports,Offices,People and More..."
              className={`mb-1 search-input`}
              value={searchText}
              onInput={(event) => {
                setSearchText(event.target.value);
              }}
              onPressEnter={onSearch}
              addonAfter={
                <SearchOutlined
                  className="text-2xl cursor-pointer hover:text-sky-400"
                  onClick={onSearch}
                />
              }
              suffix={
                <CloseOutlined
                  className={`text-2xl cursor-pointe text-gray-500 hover:text-sky-400 ${searchText !== "" ? "w-auto" : "w-0"
                    }`}
                  onClick={() => setSearchText("")}
                />
              }
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default NavBar;
