import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SEO from "@/common/SEO";
import Layout from "@/common/Layout";
import Banner from "@/components/banner/Banner";
import CardImg from "@/components/card/card-img/CardImg";
import SearchHead from "@/components/search-head/SearchHead";
import projectJson from "./projectPage.json"
import Img01 from "@/assets/img/image01.jpg";
import ImgB from "@/assets/img/Steel_Frame_Building_Under_Construction.jpg";

import NewsroomPageA from "../newsroom/NewsroomPageA";

import { Pagination } from "antd";
import { useTranslation } from "react-i18next";

import Link from "antd/es/typography/Link";
import ContactUs from "@/components/contact-us/ContactUs";

const ProjectsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const onChangePage = (page) => {
    console.log(page);
    setCurrentPage(page);
  };

  const handleProjectClick = (id) => {
    navigate(`/projects/detail/${id}`);
  };

  return (
    <>
      <SEO title="Projects"></SEO>
      <Layout>
        <Banner
          title={t("projects")}
          bgColor="#F2EDE8"
          description={t('30')}
          textColor="#0A1E41"
          img={ImgB}
        ></Banner>

        <div className="flex gap-x-10 md:flex-row flex-col page-content pt-10 pb-16">
          <div className="flex flex-col lg:flex-row md:flex-1 lg:gap-x-20">
            <div className="flex flex-col w-full">
    
      
           {/* <NewsroomPageA/> */}

           <div className="mt-10">
            <div className=" grid-cols-1 lg:grid-cols-3 gap-6" style={{display:"grid"}}>
              {projectJson.map((item, index) => (
                <div key={index} className="w-full cursor-pointer" onClick={() => handleProjectClick(item.id)}>
                  <CardImg
                    img={item.imgUrl[0]}
                    title={item.title}
                    shareIcon={true}
                    link={`/projects/detail/${item.id}`}
                    linkText={t('readMore')}
                  />
                </div>
              ))}
            </div>

            {/* <div className="flex justify-end mt-8">
              <Link href="/projects">
                <a className="mt-4 px-6 py-2 inline-block bg-blue-500 hover:bg-blue-700 rounded-lg" style={{ color: "black" }}>
                  {t('learnMore')}
                </a>
              </Link>
            </div> */}

          </div>

            </div>
          </div>
        </div>
        <ContactUs/>
      </Layout>
    </>
  );
};

export default ProjectsPage;
