import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Layout from "@/common/Layout";
import SEO from "@/common/SEO";
import Banner from "@/components/banner/Banner";

const ScrollToTopLink = ({ to, children, className }) => {
  const handleClick = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  return (
    <Link to={to} className={className} onClick={handleClick}>
      {children}
    </Link>
  );
};

const ResearchesPage = () => {
  const { t } = useTranslation();

  const researchCards = [
    {
      number: "01",
      title: t('bigDataAnalytics2'),
      description: t('bigDataResearchDesc'),
      link: "/big-data"
    },
    {
      number: "02",
      title: t('vrAndAr2'),
      description: t('vrArDesc'),
      link: "/vrar"
    },
    {
      number: "03",
      title: t('buildingConstruction'),
      description: t('digitalTwinDesc'),
      link: "/digital-twin"
    },
    {
      number: "04",
      title: t('openSourceIntelligence'),
      description: t('osintDesc'),
      link: "/osint"
    }
  ];

  return (
    <>
      <SEO title={t('researches')} />
      <Layout>
        <Banner
          title={t('researches')}
          description={t('researchesDesc')}
          bgColor="#F2EDE8"
          textColor="rgb(41, 55, 102)"
          img={'/images/researches/Document_Research_with_Magnifying_Glass.jpg'}
        />
        
        <div className="flex flex-col page-content pt-16 pb-20">
          <div className="flex flex-col md:flex-1 max-w-7xl mx-auto">
            <div className="flex flex-col w-full">
              <div 
                className="text-left mb-8"
                style={{
                  fontFamily: "'Open Sans', sans-serif",
                  fontSize: "1.125rem",
                  color: "rgb(41, 55, 102)"
                }}
              >
                {t('researchIntro2')}
              </div>
            </div>

            <div className=" grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-12" style={{display:"grid"}}>
              {researchCards.map((card) => (
                <div key={card.number} className="flex flex-col bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300">
                  <div className="mb-4">
                    <span className="text-lg font-medium border-t-2 border-l-2 border-[rgb(41,55,102)] pt-2 pl-2">
                      {card.number}
                    </span>
                  </div>
                  <h3 className="text-2xl font-bold text-[rgb(41,55,102)] mb-4">
                    {card.title}
                  </h3>
                  {/* <p className="text-gray-600 mb-6 flex-grow">
                    {card.description}
                  </p> */}
                  <ScrollToTopLink
                    to={card.link}
                    className="inline-block border border-[rgb(41,55,102)] text-[rgb(41,55,102)] px-6 py-2 hover:bg-[rgb(41,55,102)] hover:text-white transition-colors duration-200 w-fit no-underline"
                  >
                    {t('readMore')}
                  </ScrollToTopLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ResearchesPage; 